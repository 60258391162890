import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.scss'


const PageNotFound=()=>{
    const navigate= useNavigate()

    useEffect(()=>{
        setTimeout(
            () => navigate("/"),
            5000);
    },[])

    return(
        <>
        <Box className="page-not-found-wrapper">
            <h1>YIKES! Page not found!</h1>
            <h3>We didn't expect that you would end up here :(</h3>
            <p>Give us a few seconds and we will get you back to ezcocker.com.</p>
            <small>
                Tip: If you are in a big hurry - or for some reason we failed to get you there ourselves - you can click the ezClocker logo
                at the top of the page (or click <a id="EzClockerGoHomNowLink" href="/">HERE</a>) to go directly to ezclocker.com.
            </small>
        </Box>
        </>
    )
}

export default PageNotFound;