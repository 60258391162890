import React from 'react';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../ezclocker-colors.scss';
import DevicesSection from '../DevicesSection/DevicesSection';
import LandingFooter from '../landing-footer/landing-footer';
import MainFeaturesSection from '../MainFeaturesSection/MainFeaturesSection';
import RaitingsSection from '../RatingsSection/RaitingsSection';
import TeamsSection from '../TeamsSection/TeamsSection';
import TestimonialsSection from '../TestamonialsSection/TestamonialsSection';
import VideoSection from '../VideoSection/VideoSection';
import TryForFreeSection from '../TryForFreeSection/TryForFreeSection';
import './Landing.scss';

export class Landing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            redirectDomain: determineAppEnvironment()
        };
    }

    render() {
        return (
            <div className="Landing">
                <TryForFreeSection />
                <TeamsSection
                    images={[
                        {
                            id: 1,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/servpro.svg`,
                            alt: 'serverPro',
                        },
                        {
                            id: 2,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/youvegotmaids.svg`,
                            alt: 'youHaveGotMaids',
                        },
                        {
                            id: 3,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/farmers_insurance.svg`,
                            alt: 'farmersInsurance',
                        },
                        {
                            id: 4,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/UPS_store.svg`,
                            alt: 'upsStore',
                        },
                        {
                            id: 5,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/servicemaster.svg`,
                            alt: 'serviceMaster',
                        },
                        {
                            id: 6,
                            src: `${this.state.redirectDomain}/public/images/newLandingPage/littlegym.svg`,
                            alt: 'littleGym',
                        },
                    ]} />

                <MainFeaturesSection />

                <VideoSection />

                <RaitingsSection />

                <DevicesSection />

                <TestimonialsSection
                    customers={[
                        {
                            id: 1,
                            src: 'https://ezclocker.com/public/images/customers/Allan_790X790.png',
                            alt: 'allan',
                            customerName: 'Allan Dalangin',
                            customerCompany: 'Gecko Solutions, Inc.',
                            customerProfession: 'Cleaning Services',
                            link: 'https://blog.ezclocker.com/gecko-solutions-cleaning-timesheet-app-case-study/',
                        },
                        {
                            id: 2,
                            src: 'https://ezclocker.com/public/images/customers/Dale_790X790.png',
                            alt: 'dale',
                            customerName: 'Dale Henderson',
                            customerCompany: 'SiteMix – Concrete Atlanta',
                            customerProfession: 'Construction',
                            link: 'https://blog.ezclocker.com/sitemix-construction-timetracking-app-casestudy/',
                        },
                        {
                            id: 3,
                            src: 'https://ezclocker.com/public/images/customers/Oscar_790X790.png',
                            alt: 'oscar',
                            customerName: 'Oscar Jimenez',
                            customerCompany: 'OJ Handyworks',
                            customerProfession: 'Handyman',
                            link: 'https://blog.ezclocker.com/oj-handyworks-contractor-timesheets-case-study/',
                        },
                        {
                            id: 4,
                            src: 'https://ezclocker.com/public/images/customers/Jade_790X790.png',
                            alt: 'jade',
                            customerName: 'Jade Joy',
                            customerCompany: 'Austin Pet Stylist',
                            customerProfession: 'Pet Grooming',
                            link: 'https://blog.ezclocker.com/jade-austin-pet-grooming-timetracking-app/',
                        },
                        {
                            id: 5,
                            src: 'https://ezclocker.com/public/images/customers/Madeleine_Rose_790X790.png',
                            alt: 'madeleine',
                            customerName: 'Dr. Madeleine Rose',
                            customerCompany: 'Rose Gynecology & Associates',
                            customerProfession: 'Healthcare',
                            link: 'https://blog.ezclocker.com/doctor-madeleine-rose-office-timetracking-app-casestudy/',
                        },
                        {
                            id: 6,
                            src: 'https://ezclocker.com/public/images/customers/Mathew_790X790.png',
                            alt: 'matthew',
                            customerName: 'Matthew Brown',
                            customerCompany: 'Matthew’s Grounds Maintenance',
                            customerProfession: 'Landscape and Lawn Care',
                            link: 'https://blog.ezclocker.com/matthew-landscape-lawncare-timekeepingapp-casestudy/',
                        },
                        {
                            id: 7,
                            src: 'https://ezclocker.com/public/images/customers/Ag_790X790.png',
                            alt: 'merrissa',
                            customerName: 'Merrissa Burleigh',
                            customerCompany: 'American Agriculture Systems',
                            customerProfession: 'Agriculture',
                            link: 'https://blog.ezclocker.com/agriculture-timetracking-app-case-study/',
                        },
                        {
                            id: 8,
                            src: 'https://ezclocker.com/public/images/customers/Tiffany_790X790.png',
                            alt: 'tiffany',
                            customerName: 'Tiffany Sims',
                            customerCompany: 'Big Hearts Home Care',
                            customerProfession: 'Homecare',
                            link: 'https://blog.ezclocker.com/time-tracking-home-care-case-study/',
                        },
                    ]} />

                <LandingFooter />
            </div >
        );
    }
}
