import { Link } from "react-router-dom";
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "./Security.scss";

const Security = () => {

    const redirectDomain = determineAppEnvironment();

    return (
        <div className="securityPage">
            <img className="securityBannerImage" src={`${redirectDomain}/public/images/banners/security.jpg`} alt="." />
            <div className="ezClockerBasicContentContainer">
                <h2 className="security-heading">We ensure the highest level of security when working with our customers.</h2>
                <h2 className="security-heading">Privacy</h2>
                EzClocker requires a unique user name and password to allow you to control who sees your personal information.
                <h2 className="security-heading">Security and Safety</h2>
                <ul>
                    <li>
                        Your data travels safely at all times on encrypted channels through U.S based networks and servers only.
                    </li>
                    <li>
                        Your data is stored securely using the same advanced encryption technologies that banks use.
                    </li>
                </ul>
                <Link to="/">Return to Main Page </Link>
            </div>
        </div >
    )
}

export default Security;
