import React from 'react';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../ezclocker-colors.scss';
import CustomerImageCarousel from '../CustomerImageCarousel/CustomerImageCarousel';
import TestimonialsCarousel from '../TestimonialsCarousel/TestimonialsCarousel';
import './TestimonialsSection.scss';

/**
 * props: {
 *  customers: {array}
 * }
 */
export default class TestimonialsSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment(),
        }
    }

    render() {
        return (
            <div
                className="testimonial-section">
                <TestimonialsCarousel
                    testimonialObject={[
                        {
                            firstLine:
                                '"After careful review of many similar apps, the team at ezClocker gets it right. They understand and deliver. Their customer service matches their superior products."',
                            secondLine: 'joaxvergas',
                            thirdLine: '- Apple Appstore Review',
                        },
                        {
                            firstLine:
                                '"User friendly for my staff, the GPS/location system make me aware when and where they are, the quick reports for payroll and customer support was awesome. Love it, recommend definitely!!!"',
                            secondLine: 'Cathryn',
                            thirdLine: '- Owner of Maids with Care',
                        },
                        {
                            firstLine:
                                '"Very simple and easy to use application. Great for people who have employees that work from home. It helps you keep accountable and you will know that they click in and out."',
                            secondLine: 'joaxvergas',
                            thirdLine: '- Apple Appstore Review',
                        },
                        {
                            firstLine:
                                '"I have thoroughly enjoyed the simplicity of using the clock-in/clock-out feature. My employees can clock in/out from our work PC or from their phone.. Easy to use and easy to export the pay period for my payroll department."',
                            secondLine: 'johnstonmom',
                            thirdLine: '- Apple Appstore Review',
                        },
                        {
                            firstLine:
                                '"This app rocks! I own a small remodeling company (crew of 5) and this is the perfect app for me! Easy to use, and saves me tons of time!"',
                            secondLine: 'Nick Flagg',
                            thirdLine: '- Apple Appstore Review',
                        },
                    ]}
                />
                <div
                    className="meet-our-customers-main-div border-top-orange background-color-white">
                    <div
                        className="meet-our-customers-main-head">
                        Meet our customers
                    </div>
                    <div
                        className="customerImageCarousel4">
                        <CustomerImageCarousel
                            displayCount={4}
                            customers={this.props.customers} />
                    </div>
                    <div
                        className="customerImageCarousel2">
                        <CustomerImageCarousel
                            displayCount={2}
                            customers={this.props.customers} />
                    </div>
                    <div
                        className="customerImageCarousel1">
                        <CustomerImageCarousel
                            displayCount={1}
                            customers={this.props.customers} />
                    </div>
                </div>
            </div>);
    }
}
