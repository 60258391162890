import React, { useEffect } from 'react';
import './Contact.scss';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "../../assets/styles/ez-core.scss"
import "../../assets/styles/ez-responsive.scss"
import { Box, Container } from '@mui/material';
import ContactFooter from '../Contact-footer/Contact-footer';
import { Link } from 'react-router-dom';

const Contact = () => {

    const redirectDomain = determineAppEnvironment();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="contactPage">
            <Box className="contact-us-banner">
                <img className="ez-static-banner" src={`${redirectDomain}/public/images/landing/ezc_contact.jpg`} alt="Contact" />
            </Box>
            <Box className="contact-body">
                <Container>
                    <h1 className="main">Contact Us</h1>
                    <p>
                        The ezClocker team LOVES to hear from our customers (good and bad). You can contact the ezClocker team
                        through email or talk to us in person on the phone. We look forward to hearing from you!
                    </p>
                    <p>
                        Support is generally available Monday - Friday from 9:00am CST to 5:00pm CST.
                    </p>
                    <h2>Technical and Billing Support</h2>
                    <ul>
                        <li>Support email:<a href="mailto:support@ezclocker.com">support@ezclocker.com</a></li>
                        <li>Support phone:
                            (800) 769-8174</li>
                    </ul>
                    <h2>EzClocker Sales Support</h2>
                    <ul>
                        <li>Pricing Information Website:<Link to="/pricing">EzClocker Pricing</Link></li>
                        <li>Sales Email:<a href="mailto:info@ezclocker.com">info@ezclocker.com</a></li>
                        <li>Sales Phone:
                            (800) 769-8174</li>
                    </ul>
                    <ul></ul>
                    <h2>Customer Feedback</h2>
                    <p>We can't say it enough: The ezClocker team REALLY LOVES receiving feature requests, bug reports,
                        cool ideas, or comments on how you feel about the ezClocker product and team.</p>
                    <p>Customer feedback is the first thing the
                        ezClocker team considers for ALL decisions we make at ezClocker. Knowing how our customers feel
                        what they need from ezClocker is one of the greatest ways we can make sure we
                        deliver the best solution possible.</p>
                    <p>The ezClocker team believes feedback is a two-way conversation and occasionally an ezClocker
                        representative might reach out to you or reply to feedback with additional questions, comments,
                        or surveys from the ezClocker team. This conversation will help us clarify feedback,
                        verify our thinking is on target, and help us learn and improve how to best serve our customers.</p>
                    <p>Contact us today:</p>
                    <ul>
                        <li>Customer Feedback Email:<a href="mailto:feedback@ezclocker.com">feedback@ezclocker.com</a></li>
                    </ul>
                    <ul></ul>
                    <h4>Only together can we make ezClocker.com better!</h4>
                </Container>
            </Box>
            <ContactFooter />
        </div>);
}

export default Contact;
