import Favicon from 'react-favicon';
import './App.scss';
import './assets/styles/ez-color.scss';
import './assets/styles/ez-display.scss';
import './assets/styles/ez-fonts.scss';
import './ezclocker-colors.scss';
import './assets/styles/ez-body.scss';
import PageHeader from './components/PageHeader/PageHeader';
import IndexRoute from './components/Routes/IndexRoute';


export default function App() {

    return (
        <div>
            <Favicon url="/favicon.ico" />
            <PageHeader />
            <IndexRoute />
        </div>);
}
