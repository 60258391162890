import React from 'react';
import { Link } from 'react-router-dom';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../ezclocker-colors.scss';
import './EzClockerHeaderLogo.scss';

export default class EzClockerHeaderLogo extends React.Component {
    render() {
        return (
            <div className="ezclocker-logo-container">
                <Link
                    className="ez-header-logo-link"
                    to="/">
                    <img
                        className="ezclocker-logo"
                        src={`${determineAppEnvironment()}/public/images/logos/logo.svg`}
                        alt="EzClocker" />
                </Link>
            </div>);
    }
}
