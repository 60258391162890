/** @format */

export const blogNavigationItems = [
    {
        key: "EzClockerBlog",
        title: 'Blog',
        link: 'https://blog.ezclocker.com',
        type: 'absolute'
    },
    {
        key: "EzClockerBlog_CaseStudies",
        title: 'Case Studies',
        link: 'https://blog.ezclocker.com/category/case-studies/',
        type: 'absolute'
    }
];
