import React from 'react';
import Button from '@mui/material/Button';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../ezclocker-colors.scss';
import './TryForFreeSection.scss';


export default class TryForFreeSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            redirectDomain: determineAppEnvironment(),
        };
    }

    setStateWithInputValue = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    render() {
        return (
            <div
                className="try-for-free-section">
                <div
                    className="try-for-free-content">
                    <h1
                        className="try-for-free-title">
                        Small business employee time tracking software made easy
                    </h1>
                    <div
                        className="try-for-free-input-container">
                        <input
                            className="try-for-free-email-input"
                            placeholder={'Email Address'}
                            onBlur={this.setStateWithInputValue} />
                        <Button
                            className="try-for-free-button"
                            onClick={() => window.location.href =`${this.state.redirectDomain}/public/signup.html?email=${this.state.value}`}>
                            Try For Free
                        </Button>
                        <Button
                            className="try-for-free-mobile-button"
                            onClick={() => window.location.href =`${this.state.redirectDomain}/m/p/try`}>
                            Try For Free
                        </Button>
                    </div>
                    <div className="already-have-an-account-text">
                        Already have an account?&nbsp;
                        <a
                            className="already-have-an-account-link"
                            href={`${this.state.redirectDomain}/public/sign-in`}>
                            Sign In
                        </a>
                    </div>
                </div>
                <div
                    className="try-for-free-image-container">
                    <img
                        className="try-for-free-employee-dashboard-image"
                        src={`${this.state.redirectDomain}/public/images/newLandingPage/ezc_screen-phone.png`}
                        alt="Employee Dashboard" />
                </div>
            </div>);
    }
}
