/** @format */

export const ezclockerMenuItems = [
    {
        title: 'FAQ',
        link: '/faq',
        type: 'absolute',
    },
    {
        title: 'How GPS Works',
        link: '/time-tracking/gps-mobile-app/index.html',
        type: 'relative',
    },
    {
        title: 'Mobile Apps',
        link: '/time-tracking/mobile-time-clock-apps/index.html',
        type: 'relative',
    },
    {
        title: 'Compare to TSheets',
        link: '/time-tracking/alternative-tsheets/index.html',
        type: 'relative',
    },
    {
        title: 'Blog',
        link: 'https://blog.ezclocker.com',
        type: 'absolute',
    },
    {
        title: 'Case Studies',
        link: 'https://blog.ezclocker.com/category/case-studies/',
        type: 'absolute',
    },
    {
        title: 'Integrations',
        link: '/time-tracking/integrations/index.html',
        type: 'relative',
    },
];
