import React from 'react';
import Button from '@mui/material/Button';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../ezclocker-colors.scss';
import './MainFeaturesSection.scss';


export default class MainFeaturesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment()
        }
    }

    render() {
        return (
            <div
                className="main-features-section">
                <div
                    className="main-feature-card">
                    <div
                        className="clock-in-out-image-container">
                        <img
                            className="clock-in-out-image"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/shutterStock.png`}
                            alt="clock in/out" />
                    </div>
                    <div
                        className="main-feature-card-content">
                        <h1
                            className="main-feature-card-title">
                            Clock In/Out Anywhere
                        </h1>
                        <div
                            className="main-feature-card-details">
                            <p>
                                Let your employee clock in/out from anywhere using their mobile device.
                            </p>
                        </div>
                        <Button
                            variant="text"
                            className="main-feature-learn-more-button"
                            onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/mobile-time-clock-apps/index.html`}>
                            Learn more
                        </Button>
                    </div>
                    <div
                        className="main-feature-card-image-container">
                        <img
                            className="main-feature-card-image left-orange-bar"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/shutterStock.png`}
                            alt="clock in/out" />
                    </div>
                </div>

                <div
                    className="main-feature-card">
                    <div
                        className="gps-verification-image-container">
                        <img
                            className="gps-verification-image"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/map_screen.png`}
                            alt="gps" />
                    </div>
                    <div
                        className="main-feature-card-image-container">
                        <img
                            className="main-feature-card-image right-orange-bar"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/map_screen.png`}
                            alt="gps" />
                    </div>
                    <div
                        className="main-feature-card-content">
                        <h1
                            className="main-feature-card-title">
                            GPS Verification
                        </h1>
                        <div
                            className="main-feature-card-details">
                            <p>
                                Looking for a new way to verify your employees clocked in at the work site?
                                Our GPS map location view will give you the information you need.
                            </p>
                        </div>
                        <Button
                            variant="text"
                            className="main-feature-learn-more-button"
                            onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/gps-mobile-app/index.html`}>
                            Learn more
                        </Button>
                    </div>
                </div>

                <div
                    className="main-feature-card">
                    <div
                        className="online-scheduling-image-container">
                        <img
                            className="online-scheduling-image"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/shutterStock_2.png`}
                            alt="scheduleing" />
                    </div>
                    <div
                        className="main-feature-card-content">
                        <h1
                            className="main-feature-card-title">
                            Online Scheduling
                        </h1>
                        <div
                            className="main-feature-card-details">
                            <p>
                                Tired of emailing your employees their schedule? No more, our online scheduling system allows employees
                                to view their schedule anywhere using their PC or mobile device.
                            </p>
                        </div>
                        <Button
                            variant="text"
                            className="main-feature-learn-more-button"
                            onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/employee-scheduling-software/index.html`}>
                            Learn more
                        </Button>
                    </div>
                    <div
                        className="main-feature-card-image-container">
                        <img
                            className="main-feature-card-image left-orange-bar"
                            src={`${this.state.redirectDomain}/public/images/newLandingPage/shutterStock_2.png`}
                            alt="scheduleing" />
                    </div>
                </div>
            </div >);
    }
}
