import { useEffect, useState } from 'react';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";

/**
    React GTM Module
    Github: https://github.com/alinemorelli/react-gtm
    Additional Info:
    https://medium.com/finnovate-io/integrating-google-tag-manager-with-a-react-app-5a8584ee2251
 */
import TagManager from 'react-gtm-module'


/**
    @class
    @extends {React.Component}
    @description
    Initializes the Google Tag manager for Landing V2
    -----------------------------------------------------------------
    Import with:
        import { EzGoogleTagManager } from '/Google/EzGoogleTag'
    -----------------------------------------------------------------
    Use:
        <EzGoogleTagManager dataLayer="{...}"/>
    -----------------------------------------------------------------
 */
export default function EzGoogleTagManager({ gtmDataLayer = null, envId = 'Live' }) {
    const [redirectDomain] = useState(determineAppEnvironment());

    const [preProduction] = useState(
        () => {
            switch (redirectDomain) {
                case 'http://localhost:8080':
                case 'https://dev.ezclocker.com':
                case 'https://qal.ezclocker.com':
                case 'https://e2e.ezclocker.com':
                case 'https://prf.ezclocker.com':
                    // Pre-production environment
                    return true;
                case 'https://stage.ezclocker.com':
                case 'https://stg.ezclocker.com':
                case 'https://orange.ezclocker.com':
                case 'https://green.ezclocker.com':
                case 'https://blue.ezclocker.com':
                case 'https://old.ezclocker.com':
                case 'https://prd.ezclocker.com':
                case 'https://ezclocker.com':
                case 'https://eznovatech.com':
                case 'https://zclocker.com':
                default:
                    // Production environment
                    return false;
            }
        });

    const [gtmContainerId] = useState(
        () => preProduction
            // Pre-production environments
            ? 'GTM-NBV5RCJ'
            // Production environments
            : 'GTM-5WFN8JM');

    const [gtmAuth] = useState(
        () => {
            if (preProduction) {
                // Pre-production environments
                if ('Latest' === envId) {
                    return 'TO_eYhkb6SpNhRJ6By3UEA';
                }

                // Default is envId='Live'
                return 'DqXSYLly6YNrNT7uR76CLQ';
            }

            // Production
            if ('Latest' === envId) {
                return 'AHw3GlI4MjQjGQLeVLSpaA'
            }

            // Default is envId='Live'
            return 'OeLtAHmGimAszpquTTLGCg';
        });

    const [gtmPreview] = useState(
        () => {
            if ('Latest' === envId) {
                return 'env-2';
            }

            // Default is envId='Live'
            return 'env-1';
        });

    const [gtmArgs] = useState(
        () => null == gtmDataLayer
            ? {
                gtmId: gtmContainerId,
                gtm_auth: gtmAuth,
                gtm_preview: gtmPreview
            }
            : {
                gtmId: gtmContainerId,
                gtm_auth: gtmAuth,
                gtm_preview: gtmPreview,
                dataLayer: gtmDataLayer
            });

    useEffect(
        () => {
            try {
                // Attempt to intialize TagManager
                TagManager.initialize(gtmArgs);
            } catch (err) {
                console.error('Google Tag Manager is not available (possibly due to an ad or tracking blocker).');
                console.error(err);
            }
        });

    return (null);
}
