import React from 'react';

import '../../ezclocker-colors.scss';
import './TeamsSection.scss';

export default class TeamsSection extends React.Component {
    render() {
        return (
            <div className="teams-section">
                <h2
                    className="teams-section-header">
                    Teams track their time with ezClocker
                </h2>
                <div
                    className="teams-content">
                    {this.props.images.map((team, index) => {
                        return (
                            <div
                                key={team.id}
                                className="team-card">
                                <img
                                    className="team-image"
                                    src={typeof team.src === 'string'
                                        ? team.src
                                        : team.src.default}
                                    alt={team.alt} />
                            </div>);
                    })}
                </div>
            </div >);
    }
}
