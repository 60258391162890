import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "./CancellationPolicy.scss";

const CancellationPolicy = () => {

    const redirectDomain = determineAppEnvironment();

    return (
        <div className="cpPage">
            <img className="cpBannerImage" src={`${redirectDomain}/public/images/banners/cancellation.jpg`} alt="." />
            <div className="ezClockerBasicContentContainer">
                <p>
                    Please note that canceling your subscription will stop the recurring fee moving forward. You will have access
                    to the content
                    until your last subscription payment expires.
                </p>
                <p>
                    <strong>
                        Canceling a subscription will NOT retroactively refund subscription payments. Previously charged
                        subscription fees cannot be pro-rated based on the cancellation date.
                    </strong>
                </p>
                <p>
                    For example, if you purchased the $10 monthly subscription on Jan 1st and then decide to cancel your
                    subscription on Janurary
                    15th, you will continue to have access to to your account until Jan 31st. You will no longer be charged for
                    any additional
                    months after that and your account will no longer be active on Janurary 31st.
                </p>
                <h1 className="cpHeading">EzClocker Website Subscriptions</h1>
                If you subscribed to ezClocker through the website please follow the instructions below to cancel your
                subscription:
                <ol>
                    <li>Log into your current account</li>
                    <li>Navigate to the account screen</li>
                    <li>Click the Cancel Subscription button located on your account screen</li>
                </ol>
                <h1 className="cpHeading">Google Play Subscriptions</h1>
                <p>
                    You cannot cancel a Google Play subscription from the ezClocker website. If you subscribed to ezClocker
                    through your Android
                    device please click the following link for information on how to cancel your subscription.
                    <ul>
                        <li>
                            <a href="https://support.google.com/googleplay/answer/7018481">
                                Google.com: Cancel, pause, or change
                                a subscription
                                on Google Play
                            </a>
                        </li>
                    </ul>
                </p>
                <h1 className="cpHeading">Apple Store Subscriptions</h1>
                <p>
                    You cannot cancel an Apple Store subscription from the ezClocker website. If you subscribed to ezClocker
                    through your iOS
                    device please click the following link for information on how to cancel your subscription.
                    <ul>
                        <li>
                            <a href="https://support.apple.com/en-us/HT202039">
                                Apple.com: View, change, or cancel your
                                subscriptions
                            </a>
                        </li>
                    </ul>
                </p>
                <h1 className="cpHeading">Perminantly Deleting Data</h1>
                <p>
                    If you wish to perminantly delete your data you can use the delete account feature from the website or mobile apps or contact
                    ezClocker support at <a href="mailto://support@ezclocker.com">support@ezclocker.com</a> and request the perminant deletion of
                    your data.
                </p>
            </div>
            <p>
                <a href="/index.html?v=r61">Return to the ezClocker main page</a>
            </p>
        </div>
    )
}

export default CancellationPolicy;
