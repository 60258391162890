import React from 'react';
import { detect } from 'detect-browser';
import "./BrowserInfo.scss";

/**
    Provides information about the users current browser.
 */
export default class BrowserInfo extends React.Component {
    constructor() {
        super();

        this.state = {
            browserInfo: detect()
        };
    }

    render() {
        return (
            <main
                id="EzBrowserInfoPage"
                className="ezBrowserInfoPage">
                    <h3
                        id="EzBrowserInfoTitle">
                        Current Web Browser Information
                    </h3>
                    <p
                        id="EzBrowserInfoParagraph"
                        className="ezBrowserInfoContainer">
                        <div
                            id="EzBrowserInfoName">
                            <strong>Browser Name:</strong> {this.state.browserInfo.name}
                        </div>
                        <div
                            id="EzBrowserInfoName">
                            <strong>Browser Version:</strong> {this.state.browserInfo.version}
                        </div>
                        <div
                            id="EzBrowserInfoName">
                            <strong>Browser OS:</strong> {this.state.browserInfo.os}
                        </div>
                    </p>
            </main>);
    }
}
