import React from 'react';
import './VideoSection.scss';

export default class VideoSection extends React.Component {
    render() {
        return (
            <div
                className="video-section">
                <div
                    className="video-section-title">
                    <h1>
                        See a video of how EzClocker can work for you.
                    </h1>
                </div>
                <embed
                    src="https://www.youtube.com/embed/j4BWohINBY4"
                    wmode="transparent"
                    className="video-container"
                    allow="autoplay; encrypted-media; picture-in-picture"
                    title="Keyboard Cat"
                    frameBorder={0}
                    allowFullScreen></embed>
            </div>);
    }
}