import { Link } from "react-router-dom";
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "./Privacy.scss";

const Privacy = () => {

    const redirectDomain = determineAppEnvironment();

    return (
        <div className="privacyPage">
            <img className="privacyBannerImage" src={`${redirectDomain}/public/images/banners/privacy.jpg`} alt="." />
            <div className="ezClockerBasicContentContainer">
                <p>
                    <Link to="/">Return to Main Page </Link>
                </p>
                <h1 className="tosHeading">ezClocker Privacy Statement Effective 5/25/2013</h1>
                <p>
                    We will not, without your permission, sell, publish or share information you entrust to us that identifies
                    you or any person.
                </p>
                <h2 className="tosSubHeading">What Type of Information We Collect</h2>
                <ul>
                    <li>
                        Personal information such as your name, billing address, e-mail, phone, and credit card number when you
                        interact with us
                        for
                        sales, service, support, registration, and payment.
                    </li>
                    <li>Your user name and password to allow access to our products and services.</li>
                    <li>
                        Information about your system as it interacts with us such as your IP address and browser information.
                    </li>
                    <li>User feedback, community discussions, chats and other interactions at our sites.</li>
                    <li>
                        The geographic area where you use your computer and mobile devices (as indicated by an IP address or
                        similar
                        identifier). We
                        collect this information in order to help us deliver and support the Service, including by localizing
                        your experience.
                        It
                        also helps us improve our understanding of how our users use the Service.
                    </li>
                    <li>
                        Any content you add to your account. ezClocker does not use the information for any purpose other than
                        to make that data
                        available
                        to you when you use the Service.
                    </li>
                </ul>
                <p>
                    ezClocker's web site and applications might collect and receive information from your computer or mobile
                    device such as
                    activities
                    you perform within your account, hardware and software you are using, and information obtained from cookies.
                </p>

                <h2 className="tosSubHeading">Information Collected from Children</h2>
                <p>
                    ezClocker will comply with the US Children’s Online Privacy Protection Act (“COPPA”). We do not knowingly
                    collect personal
                    information
                    from children under 13 years of age without consent. If ezClocker learns that we have inadvertently collect
                    personal
                    information
                    from children under 13 years of age without consent we will delete the content.
                </p>
                <h2 className="tosSubHeading">What We Do With Your Information</h2>
                <ul>
                    <li>
                        Use your information to operate our business and to help us improve your experience with our products.
                    </li>
                </ul>

                <h2 className="tosSubHeading">Security of your Information</h2>
                <ul>
                    <li>
                        We use accepted best methods to protect your information. We review our security procedures carefully.
                    </li>
                    <li>We strive to comply with applicable laws and security standards.</li>
                    <li>Your sensitive information, such as credit card information, is sent securely.</li>
                    <li>Our staff is trained and required to safeguard your information.</li>
                </ul>

                <h2 className="tosSubHeading">Will ezClocker Send Me Emails?</h2>
                <p>
                    ezClocker will send you emails related to your ezClocker account and service. Some of these emails will
                    contain marking and
                    promotional information.
                    In addition, we also send out surveys and other questions to help us provide a better experience to all
                    ezClocker users.
                </p>

                <p>
                    The Marketing and promotional emails from ezClocker allow you to you opt out of receiving further marketing
                    and promotional
                    emails from us. If
                    you opt out of the marketing and promotional emails we will still need to send you emails related to your
                    account and
                    service.
                </p>

                <p>
                    We will never sell your information to 3rd parties or advertise to you about products not associated with
                    your ezClocker
                    account.
                </p>

                <p>This statement applies to our business operations in the United States.</p>
                <Link to="/">Return to Main Page </Link>
            </div>
        </div>
    )
}

export default Privacy;
